import React from 'react'
import SEO from '../components/seo'
import useTranslations from '../components/useTranslations'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import useHomeContent from '../components/useHomeContent'
import { Link } from 'gatsby'

const Index = () => {
    const homeContent = useHomeContent()

    return (
        <>
            <SEO title='Home' />
            <div className=''>
                {homeContent.map((content, index) => (
                    <section id={content.anchor} key={`section-${index}`} className='flex items-center relative min-h-auto px-4 py-8'>
                        <div className='container mx-auto max-w-4xl relative w-full z-10' data-sal="fade" data-sal-delay="500"
                             data-sal-duration="300" data-sal-easing="ease">
                            <div className='font-jura bg-tiles bg-opacity-90 py-8 sm:py-16 px-8 mx-4 sm:mx-10'>
                                <h2 className='text-xl md:text-5xl text-primary font-bold'>
                                    {content.name}
                                </h2>
                                {content.description && (
                                    <div className='text-md text-primary pt-4 sm:pt-8 sm:w-2/3'
                                         dangerouslySetInnerHTML={{ __html: content.description }} />
                                )}
                                {content.button && (
                                    <div className='pt-4 sm:pt-8'>
                                        <a target="_blank" href={content.link}><button
                                            className='text-lg bg-black text-white h-10 w-40 uppercase bg-opacity-80'>
                                            {content.button}
                                        </button>
                                        </a>
                                    </div>
                                )}
                            </div>
                            {index + 2 <= homeContent.length &&
                            (<div className='w-full bottom-3 w-10 mt-10 sm:mt-16 md:mt-20 text-tiles'>
                                <Link to={`#${homeContent[index + 1].anchor}`}>
                                    <svg className='w-10 mx-auto transform rotate-180' viewBox='20.5 20 159 160'
                                         fill='currentColor'
                                         xmlns='http://www.w3.org/2000/svg' data-type='shape' role='img'>
                                        <g>
                                            <path
                                                d='M100 20c-43.906 0-79.5 35.817-79.5 80s35.594 80 79.5 80c43.908 0 79.5-35.817 79.5-80S143.908 20 100 20zm0 141.177c-33.523 0-60.794-27.444-60.794-61.177S66.477 38.823 100 38.823 160.794 66.267 160.794 100 133.523 161.177 100 161.177z'></path>
                                            <path
                                                d='M103.32 82.526c-1.786-2.127-4.852-2.127-6.639 0l-19.061 22.72c-2.648 3.155-.599 8.243 3.32 8.243h38.121c3.919 0 5.967-5.087 3.32-8.243l-19.061-22.72z'></path>
                                        </g>
                                    </svg>
                                </Link>
                            </div>)
                            }
                        </div>
                        {content.image &&
                        (<div className='absolute z-0 top-0 left-0 h-full w-full'>
                            <GatsbyImage alt='section1' className='h-full w-full object-cover' image={getImage(content.image)} />
                        </div>)
                        }
                    </section>))}
            </div>
        </>
    )
}

export default Index
